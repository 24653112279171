<script lang="ts" setup>
  import { Menu } from '@bt/design-system'
  import { storeToRefs } from 'pinia'
  import { useAppStore } from '@/stores'
  import { useBreakpoints } from '~/composables/useBreakpoints'

  const appStore = useAppStore()
  const { isMobile } = useBreakpoints()
  const { menuData } = storeToRefs(appStore)
  const emit = defineEmits(['change'])

  const onSidebarElementClick = (item: any) => {
    emit('change', item)
  }
</script>

<template>
  <div class="layout-sidebar">
    <div class="sidebar-content content-wrapper">
      <div
        v-if="!isMobile"
        class="sidebar"
      >
        <Menu
          :items="menuData"
          class="menu"
          width="full"
          @change="onSidebarElementClick"
        />
      </div>
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '@/assets/styles/common.scss';

  .layout-sidebar {
    display: flex;
    margin: auto rem(16) rem(56);
    flex-direction: column;

    @include desktop-up {
      margin: auto auto rem(56);
      width: 100%;
    }

    .sidebar-content {
      display: flex;
      gap: rem(16);

      @include tablet-up {
        width: 100%;
      }

      .sidebar {
        @include tablet-up {
          width: 30%;
          max-width: rem(380);
        }

        @include desktop-up {
          width: 100%;
        }

        .menu {
          position: sticky;
          top: rem(10);
        }
      }
    }
  }
</style>
